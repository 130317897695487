import React from 'react';
import {
  SidebarIcon,
  SidebarCloseIcon,
  SidebarButtonWrapper,
  SidebarContainer,
/*  SidebarLink,
  SidebarMenu,*/
  SidebarRoute,
  SidebarWrapper
} from './SidebarElements';

const Sidebar = ({ isSidebarVisible, toggleSidebarVisibility }) => {
  return (
    <>
      <SidebarContainer
        isSidebarVisible={isSidebarVisible}
        onClick={toggleSidebarVisibility}>
        <SidebarIcon onClick={toggleSidebarVisibility}>
          <SidebarCloseIcon />
        </SidebarIcon>
        <SidebarWrapper>
          {/*<SidebarMenu>
            <SidebarLink to={'/'} onClick={toggleSidebarVisibility}>
              About
            </SidebarLink>
            <SidebarLink to={'/'} onClick={toggleSidebarVisibility}>
              Section 2
            </SidebarLink>
            <SidebarLink to={'/'} onClick={toggleSidebarVisibility}>
              Section 3
            </SidebarLink>
            <SidebarLink to={'/'} onClick={toggleSidebarVisibility}>
              Section 4
            </SidebarLink>
          </SidebarMenu>*/}
          <SidebarButtonWrapper>
            <SidebarRoute
              to={'https://t.me/m0thaf9cka'}
              onClick={toggleSidebarVisibility}>
              Contact
            </SidebarRoute>
          </SidebarButtonWrapper>
        </SidebarWrapper>
      </SidebarContainer>
    </>
  );
};

export default Sidebar;
