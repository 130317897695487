import React, { useState } from 'react';
import {
  HeroContainer,
  HeroBackground,
  VideoBackground,
  HeroContent,
  HeroTitle,
  HeroDescription,
  HeroButtonWrapper,
  ArrowForward,
  ArrowRight
} from './HeroElements';
import { Button } from '../button/ButtonElement';
import Video from '../../videos/hero-background.mp4';

const Hero = () => {
  const [hover, setHover] = useState(false);
  const onHover = () => {
    setHover(!hover);
  };
  return (
    <>
      <HeroContainer>
        <HeroBackground>
          <VideoBackground
            autoPlay
            loop
            muted
            src={Video}
            type={'video/mp4'}
          />
        </HeroBackground>
        <HeroContent>
          <HeroTitle>Hey! I'm Yevhenii.</HeroTitle>
          <HeroDescription>
            You've found my personal website, which is currently in the works. Stay tuned for the official launch!
          </HeroDescription>
          <HeroButtonWrapper>
            <Button to={'https://t.me/m0thaf9cka'} onMouseEnter={onHover} onMouseLeave={onHover}>
              Say Hello {hover ? <ArrowForward /> : <ArrowRight />}
            </Button>
          </HeroButtonWrapper>
        </HeroContent>
      </HeroContainer>
    </>
  );
};

export default Hero;
