import React, { useState } from 'react';
import Sidebar from '../components/sidebar';
import Navbar from '../components/navbar';
import Hero from '../components/hero';
/*import About from '../components/about';*/

const Home = () => {
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const toggleSidebarVisibility = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };
  return (
    <>
      <Sidebar
        isSidebarVisible={isSidebarVisible}
        toggleSidebarVisibility={toggleSidebarVisibility}
      />
      <Navbar toggleSidebarVisibility={toggleSidebarVisibility} />
      <Hero />
      {/*<About />*/}
    </>
  );
};

export default Home;
