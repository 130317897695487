import styled from 'styled-components';
/*import { Link } from 'react-scroll';*/
import { Link } from 'react-router-dom';

export const Button = styled(Link)`
  border-radius: 50px;
  background: orchid;
  white-space: nowrap;
  padding: 12px 30px;
  color: black;
  font-size: 16px;
  font-weight: 700;
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  &:hover {
    transition: all 0.2s ease-in-out;
    background: ghostwhite;
  }
`;
