import React from 'react';
import { FaBars } from 'react-icons/fa';
import {
  Nav,
  NavbarContainer,
  NavLogo,
  NavHamburgerIcon,
/*  NavMenu,
  NavItem,
  NavLink,*/
  NavButton,
  NavButtonLink
} from './NavbarElements';
import logo from '../../images/logo.png';

const Navbar = ({ toggleSidebarVisibility }) => {
  return (
    <>
      <Nav>
        <NavbarContainer>
          <NavLogo to={'/'}>
            <img src={logo} alt={'Logo'} />
          </NavLogo>
          <NavHamburgerIcon onClick={toggleSidebarVisibility}>
            <FaBars />
          </NavHamburgerIcon>
          {/*<NavMenu>
            <NavItem>
              <NavLink to={'/'}>About</NavLink>
            </NavItem>
            <NavItem>
              <NavLink to={'/'}>Section 2</NavLink>
            </NavItem>
            <NavItem>
              <NavLink to={'/'}>Section 3</NavLink>
            </NavItem>
            <NavItem>
              <NavLink to={'/'}>Section 4</NavLink>
            </NavItem>
          </NavMenu>*/}
          <NavButton>
            <NavButtonLink to={'https://t.me/m0thaf9cka'}>
              Contact
            </NavButtonLink>
          </NavButton>
        </NavbarContainer>
      </Nav>
    </>
  );
};

export default Navbar;
