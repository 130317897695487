import styled from 'styled-components';
import { FaTimes } from 'react-icons/fa';
import { Link as LinkRouter } from 'react-router-dom';
import { Link as LinkScroll } from 'react-scroll';

export const SidebarContainer = styled.aside`
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background: black;
  align-items: center;
  left: 0;
  transition: 0.3s ease-in-out;
  top: ${({ isSidebarVisible }) => (isSidebarVisible ? '0' : '-100%')};
`;

export const SidebarIcon = styled.div`
  display: flex;
  background: transparent;
  cursor: pointer;
  outline: none;
  font-size: 2rem;
  height: 80px;
  align-items: center;
  margin: 0 0 0 auto;
  padding-right: 24px;
  width: 46px;
`;

export const SidebarCloseIcon = styled(FaTimes)`
  color: white;
`;

export const SidebarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  color: ghostwhite;
`;

export const SidebarMenu = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 30px;
  text-align: center;
  margin-top: -80px;
`;

export const SidebarLink = styled(LinkScroll)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
  list-style: none;
  transition: 0.2s ease-in-out;
  color: ghostwhite;
  cursor: pointer;
  &:hover {
    color: orchid;
    transition: 0.2s ease-in-out;
  }
`;

export const SidebarButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  /*margin-top: 50px;*/
`;

export const SidebarRoute = styled(LinkRouter)`
  border-radius: 50px;
  background: orchid;
  white-space: nowrap;
  padding: 10px 36px;
  color: black;
  font-size: 18px;
  font-weight: 700;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  &:hover {
    transition: all 0.2s ease-in-out;
    background: ghostwhite;
    color: black;
  }
`;
